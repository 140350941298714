import './components/image/image';
import { init as PanelInit } from './components/panel';
import { init as StickyHeaderInit } from './components/StickyHeader';
import SwiperInit from './components/slider.js';
import { config, $ } from 'strudel';
import { onDOMReady, rIC } from './common/Utils';

config.productionTip = process.env.NODE_ENV === 'development';
config.devtools = process.env.NODE_ENV === 'development';

function ensureLoadStrudelComponents(routes) {
	routes.forEach(route => {
		const predicate = route.predicate || exist;

		if (predicate(route)) {
			route.component().then(() =>
				onDOMReady(() => {
					$(document).trigger('content:loaded');
				})
			);
		}
	});
}

const exist = route => document.querySelectorAll(route.selector).length > 0;

const every = predicate => route =>
	[]
		.concat(route.selector)
		.map(selector => ({ selector }))
		.every(predicate);

onDOMReady(() => {
	const tasks = [PanelInit, StickyHeaderInit, SwiperInit];

	function runTaskQueue(deadline) {
		while (
			(deadline.timeRemaining() > 0 || deadline.didTimeout) &&
			tasks.length
		) {
			tasks.shift()();
		}

		if (tasks.length) {
			rIC(runTaskQueue, { timeout: 1000 });
		}
	}

	rIC(runTaskQueue, { timeout: 1000 });
});

ensureLoadStrudelComponents([
	{
		selector: '#cookie-bar',
		component: () =>
			import(
				/* webpackChunkName: "CookieBar" */ './components/cookieBar'
			).then(({ default: init }) => {
				init();
			}),
	},
	{
		selector: '[data-cp="generic-list"]',
		component: () =>
			import(
				/* webpackChunkName: "GenericList" */ './components/generic-list/GenericList'
			),
	},
	{
		selector: '[data-cp="yandex-map"]',
		component: () =>
			import(
				/* webpackChunkName: "YandexMap" */ './components/yandex-map/YandexMap'
			),
	},
	{
		selector:
			'code[class*="language-"], [class*="language-"] code, code[class*="lang-"], [class*="lang-"] code',
		component: () =>
			import(
				/* webpackChunkName: "CodeHighlighter" */ './components/code-highlighter/CodeHighlighter'
			).then(({ init }) => {
				init();
			}),
	},
	{
		selector: '[data-team-crucial-link]',
		component: () =>
			import(
				/* webpackChunkName: "TeamCrucial" */ './components/teamCrucial'
			),
	},
	{
		selector: '[data-contact-us-popup-trigger]',
		component: () =>
			import(
				/* webpackChunkName: "contactUsFormPopup" */ './components/contactUsFormPopup'
			),
	},
	{
		selector: '[data-cp="anchor"]',
		component: () =>
			import(/* webpackChunkName: "Anchor" */ './components/Anchor'),
	},
	{
		selector: '[data-cp="company"]',
		component: () =>
			import(/* webpackChunkName: "Company" */ './components/Company'),
	},
	{
		selector: '[data-cp="carousel"]',
		component: () =>
			import(
				/* webpackChunkName: "Carousel" */ './components/carousel/Carousel'
			),
	},
	{
		selector: '[data-cp="contact-us-form"]',
		component: () =>
			import(/* webpackChunkName: "Form" */ './components/form/Form'),
	},
	{
		selector: '[data-cp="share-button"]',
		component: () =>
			import(
				/* webpackChunkName: "ShareButton" */ './components/share-button/ShareButton'
			),
	},
	{
		selector: '[data-cp="teammate"]',
		component: () =>
			import(/* webpackChunkName: "Teammate" */ './components/Teammate'),
	},
	{
		selector: ['[data-cp="projects"]'],
		component: () =>
			import(
				/* webpackChunkName: "Projects" */ './components/projects/Projects'
			),
	},
	{
		selector: ['[data-cp="tabs"]'],
		component: () =>
			import(
				/* webpackChunkName: "tabs" */ './components/tabs'
			),
	},
	{
		selector: ['[data-cp="comparison-slider"]'],
		component: () =>
			import(
				/* webpackChunkName: "comparisonSlider" */ './components/comparisonSlider'
			),
	},
	{
		selector: ['[data-cp="back-to-top"]'],
		component: () =>
			import(
				/* webpackChunkName: "backToTop" */ './components/backToTop'
			),
	},
	{
		selector: ['[data-cp="data-switch"]'],
		component: () =>
			import(
				/* webpackChunkName: "switchImages" */ './components/switchImages'
			),
	},
	{
		selector: ['[data-cp="image-map"]'],
		component: () =>
			import(
				/* webpackChunkName: "imageMap" */ './components/imageMap'
			),
	},
	{
		selector: ['[class="typography"]'],
		component: () =>
			import(
				/* webpackChunkName: "glueUpPrepositions" */ './components/glueUpPrepositions'
			),
	},
	{
		selector: ['[data-conversion-funnel]'],
		component: () =>
			import(
				/* webpackChunkName: "conversionFunnel" */ './components/conversionFunnel'
			),
	},
	{
		selector: '[data-cp="cases"]',
		component: () =>
			import(
				/* webpackChunkName: "Cases" */ './components/cases/Cases'
			),
	},
	{
		selector: '[data-cp="video"]',
		component: () =>
			import(
				/* webpackChunkName: "video" */ './components/video/video'
				),
	},
]);
