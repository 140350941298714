// import 'lazysizes/plugins/aspectratio/ls.aspectratio';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/rias/ls.rias';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import 'lazysizes';
//
// // window.lazySizesConfig = window.lazySizesConfig || {};
// // window.lazySizesConfig.minSize = 0; //asd
//
