import { throttle } from 'throttle-debounce';
import { Swiper } from 'swiper';

const swiperNodeList = document.querySelectorAll('[data-swiper]');

const throttleTime = 300;

function init() {
	for (let i = 0; i < swiperNodeList.length; i++) {
		const swiperNode = swiperNodeList[i];

		const attributeSettings = swiperNode.dataset.swiperSettings;

		const settings = attributeSettings ? JSON.parse(attributeSettings) : {};

		// eslint-disable-next-line no-new
		const swiperInstance = new Swiper(swiperNode, settings);

		/**
		 * BUG_NAME: S-1
		 * BUG:
		 * 		Mobile Safari resize and orientationchange problem
		 * FIX:
		 * 		Custom resize, orientationchange handling with async task
		 */
		window.addEventListener(
			'resize',
			throttle(throttleTime, resizeHandler)
		);

		window.addEventListener(
			'orientationchange',
			throttle(throttleTime, resizeHandler)
		);

		// eslint-disable-next-line no-inner-declarations
		function resizeHandler() {
			setTimeout(() => {
				swiperInstance.update();
			}, 0);
		}
	}
}

export default init;
